@import url("../../App/Vars.css");


:root {
  --bg-color: #121117;
  --new-green: #cafc4f;
  --orange: #fc9344;
  --white: #ffffff;
}

#dashboard-V3-container * {
  z-index: 100;
  font-family: "Impact", "lato";
}

#dashboard-V3-container {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.video-background {
  position: fixed;
  object-fit: cover;
  background-position: center center;
  width: 100vw;
  height: 100vh;
}

.dashboard-header {
  width: 100%;
  height: 92.5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.dashboard-header .dexynth-info {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.dashboard-header img {
  width: 80%;
}

.dashboard-header h1 {
  color: #fff;
  font-family: "Lato", Haettenschweiler, "Arial Narrow Bold", sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 20px;
}

.fade-in {
  opacity: 0;
  animation: fadeIn 1s ease-in-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.dexynth-volume {
  margin-top: 40px;
  display: flex;
  width: 80%;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 96px;
}

.dexynth-volume div {
  display: flex;
  width: 30%;
  padding: 16px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  border-radius: 2px;
  border: 1px solid var(--gris-3, #333);
}

.dexynth-volume p {
  color: var(--gris-1, #dedede);
  text-align: center;
  font-family: "Lato";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.03px;
  margin-bottom: 0rem;
}

.textEffect {
  animation: flip 3s infinite;
  animation-delay: calc(0.1s * var(--i));
}

@keyframes flip {
  0%,
  80% {
    transform: rotateY(360deg);
  }
}

.text-info-container {
  display: flex !important;
  flex-direction: row !important;
  border: none !important;
}

.text-info-container span {
  margin: -8px;
  padding: 0;
}

.dexynth-volume span {
  color: var(--new-green, #cafc4f);
  text-align: center;
  font-family: "Lato" !important;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.036px;
}

.dashboard-select-page {
  z-index: 900 !important;
  position: fixed;
  left: 97%;
  top: 45%;
}

.dashboard-select-page button {
  background: none;
  border: none;
  outline: none;
}

.dexynth-trading {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dexynth-trading .trading-info {
  width: 80%;
  flex-shrink: 0;
}

.dexynth-trading .trading-info h2 {
  color: #fff;
  font-family: "Lato";
  font-size: 96px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 940px;
}

.dexynth-trading .trading-info .orange {
  color: var(--NARANJA, #fc9344);
  font-family: "Lato";
  font-size: 96px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.dexynth-trading .trading-info h3 {
  color: var(--VERDE-LIMA, #cafc4f);
  font-family: "Lato" !important;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.039px;
  margin-top: 30px;
}

.dexynth-trading .trading-info p {
  color: var(--GRIS-1, #dedede);
  font-family: "Lato" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.016px;
  width: 65%;
}

.dexynth-desc {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.btn-trade {
  width: 400px;
  display: inline-flex;
  padding: 8px 64px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-family: "Lato" !important;
  border-radius: 5px;
  border: 1px solid var(--new-green, #cafc4f);
  color: var(--new-green, #cafc4f);
  background: var(--fondo-1-negro, #121117);
  margin-top: 20px;
  text-decoration: none;
  transition: 300ms ease-in-out;
}

.btn-trade:hover {
  background-color: var(--new-green, #cafc4f);
  color: var(--fondo-1-negro);
}

.dexynth-logo {
  position: absolute;
  top: 52%;
  left: 2%;
  width: 716px;
  height: 716px;
  border-radius: 716px;
  border: 1px solid var(--GRIS-3, #333);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.second {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 601.987px;
  height: 601.987px;
  border-radius: 601.987px;
  border: 1px solid var(--GRIS-3, #333);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.third {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 510.777px;
  height: 510.777px;
  border-radius: 510.777px;
  border: 1px solid var(--GRIS-3, #333);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.fourth {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 446.93px;
  height: 446.93px;
  border-radius: 446.93px;
  border: 1px solid var(--GRIS-3, #333);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.dexynth-features {
  position: relative;
  top: 5%;
  left: 5%;
  transform: translate(-50%, -50%);
  width: 405px;
  height: 405px;
  flex-shrink: 0;
  background-image: url("../../img/dexynth-features.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  animation: rotate 10s linear infinite;
}

.dexynth-logo-green {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 284.865px;
  height: 284.865px;
  flex-shrink: 0;
  background-image: url("../../img/dexynth-logo-green.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.desc-info {
  display: flex;
  flex-direction: column;
  margin-left: 52%;
  width: calc(100% - 716px);
}

.desc-info .info {
  display: flex;
  flex-direction: column;
  align-items: flex-staart;
}

.info-partners {
  display: flex;
  width: 70%;
  justify-content: space-around;
}

.desc-info .info .shm {
  background-image: url("../../img/ShardeumPartner.svg");
  background-repeat: no-repeat;
  width: 155px;
  height: 42px;
  flex-shrink: 0;
}

.desc-info .info .hl {
  background-image: url("../../img/hl.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 155px;
  height: 42px;
  flex-shrink: 0;
}

.desc-info .info .op {
  background-image: url("../../img/opbnb-logo.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 111px;
  height: 30px;
  flex-shrink: 0;
}

.desc-info .info .shimmer {
  background-image: url("../../img/shimmer.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 111px;
  height: 30px;
  flex-shrink: 0;
}

.desc-info .info .zetachain {
  background-image: url("../../img/ZETACHAIN.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 111px;
  height: 30px;
  flex-shrink: 0;
  margin-left: 30px;
}

.desc-info .info .ten {
  background-image: url("../../img/ten.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 111px;
  height: 30px;
  flex-shrink: 0;
  margin-left: 15px;
}

.desc-info .info .zksynk {
  background-image: url("../../img/zksynk-logo.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 137px;
  height: 27px;
  flex-shrink: 0;
  margin-left: 10px;
}

.desc-info .info h3 {
  color: var(--orange, #fc9344);
  font-family: "Lato";
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.96px;
}

.desc-info .info p {
  color: #fff;
  font-family: "Lato" !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.03px;
}

.ourPartners {
  height: 100vh;
}

.ourPartners h3 {
  color: var(--new-green, #cafc4f);
  margin-top: 8%;
  text-align: center;
  font-family: "Lato";
  font-size: 96px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 4.8px;
}

.ourPartners img {
  transition: 300ms;
}

.ourPartners img:hover {
  transform: scale(1.2, 1.2);
}

.partner-text, .partner-text * {
  font-family: "Lato" !important;
}

@media (max-width: 1000px) {
  .trading-info h2 {
    font-size: 70px !important;
    width: auto !important;
  }

  .trading-info .orange {
    font-size: 70px !important;
  }

  .dexynth-desc {
    flex-direction: column !important;
  }
}

@media (max-width: 700px) {
  .ourPartners h3 {
    font-size: 48px;
  }

  .trading-info h2,
  .trading-info span {
    font-size: 28px;
  }

  .dexynth-trading {
    height: 70vh;
  }

  .dashboard-header h1 {
    font-size: 20px;
  }

  .trading-info h2 {
    font-size: 70px !important;
    width: auto !important;
  }

  .trading-info .orange {
    font-size: 70px !important;
  }

  .dexynth-desc {
    flex-direction: column !important;
  }

  .dexynth-volume {
    gap: 20px;
    grid-gap: 20px;
  }

  .dexynth-volume div {
    height: 100%;
  }

  .text-info-container {
    height: 30px !important;
  }

  .dexynth-desc {
    height: auto;
  }

  .desc-info {
    width: 100% !important;
    margin: 0 auto !important;
  }

  .dexynth-logo {
    position: static;
    margin-bottom: 50px;
  }

  .dexynth-logo {
    width: 490px;
    height: 490px;
  }

  .second {
    width: 412.68px;
    height: 412.68px;
  }

  .third {
    width: 350.08px;
    height: 350.08px;
  }

  .fourth {
    width: 306.13px;
    height: 306.13px;
  }

  .dexynth-features {
    width: 278.96px;
    height: 278.96px;
  }

  .dexynth-logo-green {
    width: 195.66px;
    height: 195.66px;
  }

  .desc-info {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .desc-info .info {
    width: 80%;
  }

  .info-partners {
    width: auto;
  }

  .ourPartners {
    height: auto;
  }

  .ourPartners .col-3 {
    width: 100% !important;
  }

  .ourPartners .mt-10 {
    margin-top: 0 !important;
  }

  .ourPartners .col-3 img {
    margin-top: 50px;
    width: 50%;
  }
}

@media (max-width: 550px) {
  .trading-info .orange,
  .trading-info h2 {
    font-size: 48px !important;
  }
  .btn-trade {
    width: auto;
    text-align: center;
  }

  .dashboard-header h1 {
    font-size: 40px;
    width: 80%;
    text-align: center;
  }

  .dexynth-logo {
    width: 300px;
    height: 300px;
    margin-top: 50px;
  }

  .second {
    width: 251.23px;
    height: 251.23px;
  }

  .third {
    width: 213.46px;
    height: 213.46px;
  }

  .fourth {
    width: 186.86px;
    height: 186.86px;
  }

  .dexynth-features {
    width: 170.68px;
    height: 170.68px;
  }

  .dexynth-logo-green {
    width: 119.05px;
    height: 119.05px;
  }

  .desc-info .info h3 {
    font-size: 30px;
  }

  .desc-info .info p {
    font-size: 14px;
  }

  .partner-text {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .ourPartners {
    margin-bottom: 100px;
  }

  .info-partners {
    display: flex;
    flex-direction: column;
  }

  .info-partners div {
    margin-bottom: 20px;
  }

  .desc-info .info .shm {
    height: 28px;
  }

  .dexynth-volume div {
    width: 40%;
  }

  .dexynth-volume span {
    font-size: 14px;
  }
}
