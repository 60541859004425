@import url("../../App/Vars.css");

.Otc {
  max-width: 155rem;
  margin: auto;
  justify-content: space-between;
}

.otc-layout {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.info-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--gris-3);
  width: 24%;
  height: 160px;
}

/* Estilos Fran */
.otc-info {
  width: 30%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  margin-bottom: 50px;
}

.otc-tab {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 60px;
  width: 100%;
  border-bottom: 1px solid var(--gris-3);
}

.first-element {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.1em;
  color: rgba(255, 255, 255, 0.38);
}

.second-element {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #cafc4f;
}

.red {
  background: radial-gradient(69.12% 69.12% at 47.56% 76.47%, #a60303 0%, #ff9900 67.19%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.otc-main {
  width: 70%;
  border: 1px solid #403c1a;
  border-radius: 8px;
}

.otc-buttons {
  width: 100%;
  display: flex;
}

.otc-buttons button {
  border: none;
  border-bottom: 1px solid #1a4027;
  padding: 10px;
  width: 50%;
  background-color: transparent;
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  text-transform: uppercase;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.1em;
  color: rgba(255, 255, 255, 0.38);
}

.otc-buttons .second-btn {
  border-left: 1px solid #403c1a;
}

.otc-text {
  font-family: "Lato";
  text-transform: uppercase;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.1em;
  color: rgba(255, 255, 255, 0.38);
}

.button-container {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  padding-bottom: 20px;
}

.button-end {
  color: var(--fondo-1-negro);
  border: none;
  outline: none;
  padding: 5px 0px;
  margin: 0 auto;
  width: 80%;
  cursor: pointer !important;
  background-color: var(--verde-lima);
  border: 1px solid var(--gris-4);
}

.contenedor-otc {
  background-color: black;
  width: 50vw;
  margin: 0 auto;
  padding: 50px 20px;
  border: 1px solid var(--gris-4);
}

.otc-swap {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.otc-content {
  padding: 15px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.otc-input {
  width: 60%;
  border: 1px solid var(--gris-4);
  border-right: none;
  background-color: var(--fondo-1-negro);
}

.otc-input::placeholder {
  color: var(--gris-1);
  text-align: right;
}

.label {
  border: 1px solid var(--gris-4);
  border-left: none;
}

.otc-input-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.otc-label {
  width: 80%;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.max-button {
  background-color: transparent;
  border: none;
  color: var(--gris-1);
}

.Otc-trigger-order-info,
.Otc-trigger-order-info a {
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid #23263b;
}

.Otc-list-tab-container {
  margin-top: -40px;
  margin-bottom: 20px;
}

.otc-contenedor {
  background-color: var(--fondo-2-gris);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid var(--gris-3);
  width: 25vw;
  margin: 0 auto;
}

.Otc-should-show-position-lines {
  font-size: 1.4rem;
}

.Otc-should-show-position-lines .active {
  opacity: 1;
}

.Otc-empty-positions-list-note {
  padding-bottom: 0.465rem;
}

.position-loading-icon {
  display: inline-block;
  font-size: 1.25rem;
  margin-left: 0.8rem;
  color: #3749e6;
  vertical-align: middle;
  margin-top: -0.31rem;
}

.Otc-settings-row {
  margin-bottom: 0.8rem;
}

.Otc-settings .App-cta {
  margin-top: 1.5rem;
}

.Otc-content {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 1.5rem;
  padding: 3.1rem;
  padding-top: 0rem;
  margin-top: -1.5rem;
}

.Otc-position-list-orders {
  white-space: nowrap;
}

.Otc-lists {
  margin-top: 20px;
}

.Otc-lists.large {
  display: block;
}

.Otc-lists.small {
  display: none;
}

.Otc-right {
  display: grid;
  grid-template-rows: auto 1fr;
}

.Otc-leverage-slider-settings {
  font-size: 1.4rem;
  margin-bottom: 0.8rem;
}

.Otc-leverage-slider-settings .Checkbox {
  display: grid;
  grid-template-columns: 1fr auto;
  direction: rtl;
}

.Otc-wallet-tokens {
  position: relative;
  min-height: 15.5rem;
  display: none;
}

.Otc-wallet-tokens-content {
  position: absolute;
  top: 1.5rem;
  bottom: 0;
  left: 0;
  right: 0;
}

.Otc-bottom-header-item {
  display: inline-block;
  margin-right: 1.5rem;
  margin-bottom: 1.5rem;
  padding-left: 0.155rem;
  opacity: 0.7;
  cursor: pointer;
}

.Otc-bottom-header-item.active {
  opacity: 1;
  pointer-events: none;
}

.Otc-swap-connect-wallet {
  text-align: center;
  cursor: pointer;
  border: 1px solid rgb(50, 50, 50);
  border-radius: 3px;
}

.Otc-swap-connect-wallet:hover {
  background: #272e33;
  border-radius: 0.6rem;
}

.Otc-swap-settings.Otc-swap-txns-status {
  text-align: right;
  margin-right: 1.5rem;
}

.Otc-swap-account .Otc-swap-address:hover,
.Otc-swap-account .Otc-swap-txns-status:hover {
  opacity: 1;
}

.Otc-leverage-slider {
  margin-top: 1.5rem;
  margin-bottom: 3.4rem;
  padding: 0 0.8rem;
}

.Otc-swap-leverage-options {
  text-align: right;
}

.Otc-swap-leverage-option {
  display: inline-block;
  margin-left: 0.8rem;
  padding: 0 0.8rem;
  border-radius: 3px;
  background: #5b2ab0;
  color: var(--gris-1);
  opacity: 0.7;
  cursor: pointer;
}

.Otc-swap-leverage-option.active {
  background: #842ec9;
  opacity: 1;
  pointer-events: none;
}

.Otc-swap-leverage-option:hover {
  opacity: 1;
}

.Otc-swap-leverage-unlock-icon {
  font-size: 0.8rem;
  vertical-align: middle;
  margin-top: -0.31rem;
}

.Otc-swap-placeholder {
  height: 11.175rem;
  position: relative;
}

.Otc-wave {
  z-index: 1;
  position: absolute;
  left: 1.5rem;
  right: 1.5rem;
  top: 1.5rem;
  bottom: 1.5rem;
  mask-image: url(img/wave-01.png);
  mask-size: 90% 90%;
  mask-repeat: no-repeat;
  mask-position: center;
  filter: blur(5rem);
  background: linear-gradient(
    40deg,
    rgba(96, 4, 189, 1) 0%,
    rgba(65, 105, 224, 1) 22%,
    rgba(99, 8, 191, 1) 23%,
    rgba(51, 9, 115, 1) 37%,
    rgba(255, 36, 145, 1) 38%,
    rgba(201, 16, 161, 1) 50%,
    rgba(171, 10, 163, 1) 59%,
    rgba(117, 4, 128, 1) 67%,
    rgba(96, 29, 196, 1) 68%,
    rgba(92, 55, 204, 1) 84%,
    rgba(84, 88, 214, 1) 100%
  );
  pointer-events: none;
}

.PositionEditor-info-box {
  margin-bottom: 1.05rem;
}

.PositionEditor-keep-leverage-settings .Checkbox {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  direction: rtl;
  font-size: 1.4rem;
  margin-bottom: 0.465rem;
}

.PositionEditor-accept-profit-warning .Checkbox {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  direction: rtl;
  font-size: 1.4rem;
  margin-bottom: 0.465rem;
}

.PositionEditor-allow-higher-slippage .Checkbox {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  direction: rtl;
  font-size: 1.4rem;
  margin-bottom: 0.465rem;
}

.ExchangeChart-range-option {
  display: inline-block;
  margin-right: 1.5rem;
  font-size: 1.4rem;
  cursor: pointer;
  opacity: 0.6;
}

.ExchangeChart-range-option:hover {
  opacity: 0.8;
}

.ExchangeChart-range-option.active {
  opacity: 1;
}

.ExchangeChart-main-price {
  font-size: 1.7rem;
}

.ExchangeChart-title {
  font-size: 2.15rem;
  font-weight: bold;
}

.ExchangeChart-info-label {
  font-size: 1.25rem;
  /* opacity: 0.7; */
}

.ExchangeChart-top-inner {
  display: grid;
  grid-template-columns: auto auto auto auto auto 1fr;
  grid-column-gap: 4.65rem;
  align-items: center;
  padding-left: 0.3rem;
}

.ExchangeChart-dollar-sign {
  display: inline-block;
  margin-right: 0.465rem;
  opacity: 0.7;
}

.ExchangeChart-main-price-text {
  display: inline-block;
}

.ExchangeChart-top {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: auto;
  z-index: 2;
}

.ExchangeChart.tv .ExchangeChart-top {
  position: relative;
  z-index: 2;
}

.ExchangeChart.tv .ExchangeChart-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 6.975rem;
  z-index: 1;
}

.ExchangeChart-bottom-controls {
  display: inline-block;
}

.ExchangeChart-bottom-controls .Tab .Tab-option {
  width: 4.65rem;
  padding: 0.8rem 0;
}

.ExchangeChart.tv .ExchangeChart-bottom-header {
  position: absolute;
  display: flex;
  top: 1.5rem;
  left: 1.5rem;
  right: 1.5rem;
  z-index: 5;
}

.ExchangeChart.tv .ExchangeChart-bottom-stats {
  font-size: 1.4rem;
  padding: 0.8rem 0;
  margin-left: 3.1rem;
  white-space: nowrap;
  z-index: 2;
}

.ExchangeChart-bottom-stats-label {
  color: rgba(255, 255, 255, 0.7);
  font-weight: bold;
}

.ExchangeChart-bottom-stats-value {
  display: inline-block;
  margin-left: 0.31rem;
  margin-right: 0.8rem;
}

.length-5 .ExchangeChart-bottom-stats-value {
  width: 6.2rem;
}

.length-4 .ExchangeChart-bottom-stats-value {
  width: 5.25rem;
}

.length-3 .ExchangeChart-bottom-stats-value {
  width: 4.35rem;
}

.length-2 .ExchangeChart-bottom-stats-value {
  width: 3.41rem;
}

.length-1 .ExchangeChart-bottom-stats-value {
  width: 2.48rem;
}

.ExchangeChart.tv .ExchangeChart-bottom-content {
  position: absolute;
  bottom: 0.8rem;
  left: 0;
  right: 0;
  top: 0.8rem;
}

.Otc-price-tooltip {
  font-size: 1.4rem;
  background: linear-gradient(90deg, rgba(57, 28, 147, 0.9) 0%, rgba(66, 24, 140, 0.9) 100%);
  padding: 0.9rem;
  border: 1px solid rgba(30, 9, 94, 0.5);
  line-height: 2rem;
}

.Otc-price-time {
  font-size: 1.25rem;
  font-weight: normal;
}

.Error-modal .Modal-content {
  width: 31rem;
}

.PositionEditor .Modal-content {
  width: 38rem;
  position: absolute;
  max-height: 80vh;
  overflow: hidden;
  border: none;
  top: auto;
  bottom: auto;
}

.PositionEditor .Modal-content .Modal-body {
  overscroll-behavior: smooth;
  max-height: calc(80vh - 8.525rem);
  overflow-y: auto;
  padding-right: 0.5rem;
  margin: 1.5rem;
  margin-right: 1rem;
}

.PositionEditor .Tab {
  margin-bottom: 1.05rem;
}

.PositionEditor-token-symbol {
  font-size: 2.1rem;
  text-align: right;
}

.ExchangeChart-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.Otc-list-modal .Modal-content {
  width: 36rem;
}

.Otc-list {
  width: 100%;
}

.Position-list-order {
  white-space: nowrap;
  margin-top: 8px;
}

.order-error span {
  text-decoration-color: var(--error-red);
  color: var(--error-red);
}

.order-error .Tooltip-popup {
  white-space: pre-line;
}

.Otc-list .App-card {
  margin-bottom: 1.5rem;
  border: 1px solid var(--gris-4);
}

.Otc-list-item-error {
  color: #e23e3e;
  margin-top: 0.465rem;
  font-size: 1.4rem;
}

.Otc-list.small {
  display: none;
}

button.Otc-list-action {
  font-size: 1.5rem;
  padding: 0;
  border-radius: 3px;
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.7);
  margin: 0 0.3rem;
}

button.Otc-list-action:hover {
  color: rgba(255, 255, 255, 1);
}

table.Otc-list {
  border-collapse: collapse;
}

table.Otc-list th,
table.Otc-list td {
  font-weight: normal;
  text-align: left;
  padding: 1.05rem;
  padding-left: 1.5rem;
}

table.Otc-list.Orders th,
table.Otc-list.Orders td {
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
}

table.Otc-list tr {
  border-bottom: 1px solid #23263b;
}

table.Otc-list tr:last-child {
  border-bottom: none;
}

table.Otc-list tr:hover {
  background: linear-gradient(90deg, rgba(30, 34, 61, 1) 0%, rgba(38, 43, 71, 1) 100%);
  box-shadow: inset 0px 0px 3rem 0.5rem rgba(255, 255, 255, 0.01);
}

table.Otc-list tr.Otc-list-header {
  background: none;
}

table.Otc-list tr.Otc-list-header th {
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
}

table.Otc-list th {
  opacity: 0.7;
}

.Otc-leverage-box {
  margin-bottom: 1.05rem;
}

.Otc-swap-box-info {
  margin-bottom: 1.05rem;
}

.Otc-info-row {
  display: grid;
  grid-template-columns: auto auto;
  font-size: 1.4rem;
  margin-bottom: 0.465rem;
}

.Otc-info-row.top-line {
  border-top: 1px solid #23263b;
  margin-top: 1.5rem;
  padding-top: 1.5rem;
}

.Otc-info-label-button {
  opacity: 0.7;
  cursor: pointer;
}

.Otc-info-label-button a {
  text-decoration: none;
}

.Otc-info-label-button:hover {
  opacity: 0.9;
}

.Otc-info-label {
  opacity: 0.7;
  margin-right: 0.8rem;
}

.Otc-info-value-warning {
  color: #e23e3e;
}

.Confirmation-box-info,
.Confirmation-box-info a,
.Confirmation-box-warning,
.Confirmation-box-warning a {
  font-size: 1.4rem;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.Confirmation-box-info,
.Confirmation-box-info a {
  opacity: 0.7;
}

.Confirmation-box-warning,
.Confirmation-box-warning a {
  color: #e23e3e;
}

.Otc-swap-usd {
  display: inline-block;
  font-size: 1.4rem;
}

.Otc-swap-box {
  width: 41.85rem;
  display: grid;
  position: relative;
}

.Otc-swap-market-box-title {
  font-size: 1.5rem;
  margin-bottom: 1.25rem;
}

.Otc-swap-box-inner {
  padding: 1.5rem;
}

.Otc-swap-market-box {
  padding: 1.5rem;
  margin-top: 1.25rem;
  padding-bottom: 1.5rem;
}

.Otc-swap-market-box .App-card-divider {
  margin: 0 -1.5rem 1.5rem;
}

.Otc-swap-box .Radio {
  margin-bottom: 1.05rem;
}

.Otc-swap-box .Overlay-content-outer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 15.5rem;
}

.Otc-swap-box .Overlay-content {
  padding: 1.05rem;
}

.Otc-swap-box .TokenSelector .Modal,
.Selector .Modal {
  align-items: flex-start;
  justify-content: flex-end;
}

.Otc-swap-box .TokenSelector .Modal-content,
.Selector .Modal-content {
  width: 41.85rem;
  position: absolute;
  max-height: 100vh;
  top: 0;
  bottom: 0;
  overflow: hidden;
  border: none;
}

.Otc-swap-box .TokenSelector .Modal-content .Modal-body {
  scroll-margin-right: 38.75rem;
  overscroll-behavior: smooth;
  scrollbar-width: thin;
  padding-right: 0.5rem;
  max-height: calc(100vh - 7.75rem);
  overflow-y: auto;
}

.Otc-swap-box .Tab {
  margin-bottom: 1.05rem;
}

.Otc-swap-section {
  width: 100%;
  padding: 1.5rem;
  margin-bottom: 1.05rem;
  border-radius: 0.3rem;
  background: linear-gradient(90deg, rgba(30, 34, 61, 0.9) 0%, rgba(38, 43, 71, 0.9) 100%);
  box-shadow: inset 0px 0px 3rem 0.5rem rgba(255, 255, 255, 0.01);
}

.Otc-swap-option-tabs.Tab.block .Tab-option {
  padding: 1.05rem;
}

.Otc-swap-order-type-tabs {
  margin-top: 1.5rem;
}

.Otc-swap-option-tabs.Tab.block .Tab-option-icon {
  margin-top: -0.155rem;
  transform: scale(0.75);
  vertical-align: middle;
  margin-right: 0.8rem;
}

.Otc-swap-section-top {
  display: grid;
  grid-template-columns: auto auto;
  font-size: 1.4rem;
  padding-bottom: 1.25rem;
}

.Otc-swap-section-bottom {
  display: grid;
  grid-template-columns: 1fr auto;
  padding-bottom: 0.31rem;
}

.Otc-swap-message {
  font-size: 1.4rem;
  opacity: 0.7;
  margin: 0.5rem 0;
}

.Otc-swap-input-container {
  position: relative;
  overflow: hidden;
}

.Otc-swap-max {
  position: absolute;
  right: 1.25rem;
  top: 0;
  padding: 0.465rem 0.8rem;
  color: rgba(255, 255, 255, 0.7);
  outline: 1rem solid rgba(33, 37, 64, 1);
  border-radius: 3px;
  z-index: 1;
  cursor: pointer;
  font-size: 1.5rem;
  background: linear-gradient(90deg, rgba(45, 66, 252, 1) 0%, rgba(46, 61, 205, 1) 100%);
}

.Otc-swap-max:hover {
  color: rgba(255, 255, 255, 1);
  background: linear-gradient(90deg, rgba(58, 78, 252, 1) 0%, rgba(51, 68, 222, 1) 100%);
}

.Otc-swap-section-bottom .TokenSelector-box {
  font-size: 2.1rem;
}

.Otc-leverage-box .TokenSelector-caret {
  font-size: 1.5rem;
  margin-left: 0.31rem;
}

input.Otc-swap-input {
  padding: 0;
  max-width: 100%;
  font-size: 2.325rem;
  width: 100%;
  padding-right: 2rem;
}

input.Otc-swap-input.small {
  max-width: 14.725rem;
}

.Otc-swap-button-container {
  padding-top: 0.31rem;
}

.Otc-swap-button {
  display: block;
  width: 100%;
}

.Otc-swap-ball-container {
  position: relative;
  z-index: 1;
}

.Otc-swap-ball-icon {
  text-align: center;
  display: block;
  transform: rotate(90deg);
  font-size: 2rem;
  opacity: 0.7;
}

.Otc-swap-ball {
  position: absolute;
  width: 3.565rem;
  height: 3.565rem;
  left: 50%;
  margin-left: -1.7825rem;
  top: -1.9375rem;
  border-radius: 3.1rem;
  cursor: pointer;
  user-select: none;
  background: linear-gradient(90deg, rgba(45, 66, 252, 1) 0%, rgba(46, 61, 205, 1) 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.Otc-swap-ball:hover {
  background: linear-gradient(90deg, rgba(58, 78, 252, 1) 0%, rgba(51, 68, 222, 1) 100%);
}

.Otc-swap-ball:hover .Otc-swap-ball-icon {
  opacity: 1;
}

table.Otc-list-small {
  width: 100%;
  background: linear-gradient(45deg, rgba(11, 5, 55, 0.6) 0%, rgba(21, 3, 48, 0.6) 100%);
}

table.Otc-list-small th,
table.Otc-list-small td {
  font-weight: normal;
  text-align: left;
  padding: 1.05rem;
}

.Otc-list-card-header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 0.8rem;
}

.Otc-list-title {
  width: 3.1rem;
  display: inline-block;
  white-space: nowrap;
}

.Otc-list-info-label {
  font-size: 1.4rem;
  margin-top: 0.31rem;
}

.Otc-list-muted {
  font-size: 1.4rem;
}

.Otc-list-card .Otc-list-side {
  text-align: right;
}

.Otc-list-card .Otc-list-size-info {
  margin-bottom: 0.8rem;
}

.Confirmation-box {
  font-size: 2rem;
}

.Confirmation-box-main {
  text-align: center;
  display: flex;
  flex-direction: column;
  margin: 1.5rem 0 2.325rem;
}

.Confirmation-box-main-icon {
  margin: 0.5rem 0;
}

.Confirmation-box-main-icon:after {
  content: "↓";
}

.Confirmation-box-main-icon.dot:after {
  content: "•";
}

.Confirmation-box .Modal-content {
  width: 36rem;
}

.Confirmation-box-row {
  margin-top: 1.5rem;
}

.Confirmation-box-button {
  margin-top: 1rem;
  width: 100%;
}

.Otc-footer {
  text-align: center;
  padding-top: 3.1rem;
  height: 7.75rem;
}

.cancel-order-btn {
  margin-right: 1.5rem;
  background: none;
  border: none;
  color: var(--gris-1);
  padding: 0;
}

.cancel-order-btn:hover {
  opacity: 0.8;
}

.cancel-order-btn:disabled {
  opacity: 0.6;
}

.chart-positions:hover {
  opacity: 0.8;
}

.chart-positions.span:hover {
  opacity: 1;
}

.al-swap .Tooltip-popup {
  min-width: 25rem;
}

.position-list-collateral {
  display: flex;
  align-items: flex-end;
}

.position-list-collateral .edit-icon {
  cursor: pointer;
  margin-left: 0.5rem;
  display: inline-flex;
  align-items: center;
  opacity: 0.7;
}

.Otc-list-tabs {
  border-bottom: 1px solid var(--gris-4);
  padding: 20px;
  display: flex;
  justify-content: space-around;
  width: 400px;
  margin-left: -41px;
  text-align: center;
}

.position-list-collateral .edit-icon:hover {
  opacity: 1;
}

@media (max-width: 1300px) {
  .Otc-list.large {
    display: none;
  }

  .Otc-list.small {
    display: table;
  }
}

@media (max-width: 1100px) {
  .ExchangeChart.tv .ExchangeChart-bottom-header {
    display: block;
  }

  .ExchangeChart.tv .ExchangeChart-bottom-stats {
    margin-left: 0;
  }

  .ExchangeChart.tv .ExchangeChart-bottom-content {
    position: absolute;
    bottom: 0.8rem;
    left: 0;
    right: 0;
    top: 3.875rem;
  }

  .Otc-list-tabs {
    margin-left: -40px;
    margin-top: 0;
  }

  .Otc-wallet-tokens {
    display: none;
  }

  .Otc-swap-placeholder {
    display: none;
  }

  .ExchangeChart {
    height: 38.75rem;
  }

  .Otc-content {
    grid-template-columns: 1fr;
  }

  .Otc-lists.large {
    display: none;
  }

  .Otc-lists.small {
    display: block;
  }

  .Otc-swap-box .TokenSelector .Modal {
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }

  .order-error-message {
    white-space: pre-line;
    display: block;
    margin-bottom: 10px;
  }

  .Otc-swap-box .TokenSelector .Modal-content,
  .Otc-swap-box .Selector .Modal-content {
    /* display: block;
    margin: 0;
    padding: 1.5rem;
    box-sizing: border-box;
    height: auto;
    border: 1px solid #1c0e6a;
    width: 100%; */
  }

  .Otc-swap-box .TokenSelector .Modal-content,
  .Selector .Modal-content {
    width: 41.85rem;
    position: absolute;
    max-height: 80vh;
    overflow: hidden;
    border: none;
    top: auto;
    bottom: auto;
  }

  .Otc-swap-box .TokenSelector .Modal-content .Modal-body {
    overflow-y: auto;
    max-height: calc(70vh - 8.525rem);
    scroll-margin-right: 38.75rem;
    overscroll-behavior: smooth;
    scrollbar-width: thin;
    padding-right: 0.5rem;
    margin: 1.5rem 0.8rem 1.5rem 1.5rem;
  }

  .Otc-swap-box .TokenSelector .Modal,
  .Selector .Modal {
    align-items: center;
    justify-content: center;
  }

  input.Otc-swap-input {
    max-width: 15.5rem;
  }

  .ExchangeChart-bottom-controls .Tab .Tab-option {
    width: 3.875rem;
    padding: 0.465rem 0;
    font-size: 1.25rem;
  }
}

@media (max-width: 850px) {
  .otc-contenedor {
    width: 50vw;
  }
}

@media (max-width: 700px) {
  .PositionEditor .Modal-content {
    width: 90vw;
  }

  .ExchangeChart-top-inner {
    grid-template-columns: auto auto auto;
    grid-column-gap: 0.8rem;
  }

  .ExchangeChart-title {
    font-size: 1.85rem;
  }

  .ExchangeChart-additional-info {
    display: none;
  }

  .Otc-content {
    padding: 1.5rem;
    padding-top: 1.5rem;
  }

  .Otc-swap-box .TokenSelector .Modal {
    margin: 0 1.5rem;
  }

  .Otc {
    padding-top: 1.5rem !important;
  }
}

@media (max-width: 630px) {
  .otc-layout {
    justify-content: flex-start;
  }

  .otc-info {
    flex-wrap: wrap;
  }

  .otc-info .info-content {
    width: 49% !important;
    margin-bottom: 5px;
  }

  .otc-contenedor {
    width: 73vw;
  }
}

.otc-contenedor .otc-tab {
  display: flex;
  justify-content: center;
}

.otc-contenedor .Tab-option {
  color: white !important;
  width: 40%;
  padding: 10px;
  text-align: center;
  margin-left: 10px;
}

.otc-contenedor .Tab-option .muted {
  background-color: #3c3d43 !important;
  color: white;
}

.otc-contenedor .Tab-option .white {
  color: white !important;
}

.otc-title {
  color: var(--NARANJA, #fc9344);
  text-align: center;
  font-family: "Lato";
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 6.4px;
  margin-bottom: 20px;
  position: absolute;
  top: 120px;
}

@media (max-width: 315px) {
  .otc-layout {
    justify-content: flex-start;
  }

  .otc-info {
    width: 90vw !important;
    padding: 0px !important;
  }

  .otc-info .info-content {
    width: 100% !important;
    margin-bottom: 5px;
  }

  .otc-contenedor {
    width: 90vw;
  }
}
